import {
  CREATE_AUTHOR,
  FIND_AUTHOR, GET_AUTHORS_FOR_ALL, GET_AUTHORS_LIST,
  SET_AUTHOR_ERROR, SWITCH_AUTHOR_STATE,
  UPDATE_AUTHOR
} from "../store/types";
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";

export default {
  data(){
    return {
      authorsForStore: [],
      authorsForStoreMeta: {}
    }
  },
  methods: {
    deleteAuthor(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/authors/${id}`)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_AUTHOR_ERROR);
          reject(response);
        })
      })
    },
    createAuthor(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(CREATE_AUTHOR, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    updateAuthor(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(UPDATE_AUTHOR, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    findAuthor(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_AUTHOR, id)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getAuthors(options = {page: 1, title: ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_AUTHORS_LIST, options)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    switchAuthorState(id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_AUTHOR_STATE, {id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getAuthorsForStore(title = false, page = 1, busy = false){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post('/manager/authors/list-for-store', {title, page})
        .then(response=>{
          let { data, meta } = response.data;
          this.authorsForStore = data;
          this.authorsForStoreMeta = meta;
          this.$store.commit(SET_AUTHOR_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_AUTHOR_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    getAuthorsForAll(busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_AUTHORS_FOR_ALL)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    }
  }
}
